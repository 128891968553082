import { Component, createContext, useContext } from 'react';
import { Router as BrowserRouter, StaticRouter } from 'react-router-dom';

import { isBrowser, safeWindow as window } from '@/lib/browser';
import config from '@/lib/config';
import history from '@/lib/history';
import { consumerToHOC } from '@/lib/hoc';
import qs from '@/lib/qs';

const Context = createContext({});

function Router({ children, history, serverData }) {
  if (!isBrowser) {
    const { pathname, searchParams } = serverData;
    const search = qs.stringify(searchParams);

    return (
      <StaticRouter location={{ pathname, search }}>{children}</StaticRouter>
    );
  }

  return <BrowserRouter history={history}>{children}</BrowserRouter>;
}

class Provider extends Component {
  unlisten = null;

  constructor(props) {
    super(props);

    let initialSearch = props.serverData.searchParams;

    // Regiser route change listener
    this.unlisten = history.listen(this.handleRouteChange);

    const search = this.getParsedSearch(initialSearch);

    const { filteredSearch, filteredOut, filtered } =
      this.filterValidParams(search);

    // Initialize state
    this.state = {
      clientId: initialSearch.clientId,
      redirectBackTo: initialSearch.redirect,

      parsedSearchParams: search ?? {},
      parsedTrackingParams: this.getParsedTrackingParams(initialSearch),

      lastMainMobileRoute: 'spaceMap',
    };

    // Only keep valid params on the URL, strip all the rest
    if (filtered) {
      // NOTE
      // Only filter out invalid params after 1 minute to give marketing
      // tools enough time to try to do their thing before we potentially
      // remove something they might need.
      //
      // TODO we could consider sending the filteredOut parms to our /log
      // route in the app server.
      setTimeout(() => {
        console.info(
          'Some invalid search params were filtered out: ',
          filteredOut,
        );

        history.replace({
          search: qs.stringify(filteredSearch),
          hash: window.location.hash ?? '',
        });
      }, 1000 * 60);
    }
  }

  componentWillUnmount() {
    if (this.unlisten === 'function') {
      this.unlisten();
    }
  }

  render() {
    const context = {
      parsedSearchParams: this.state.parsedSearchParams ?? {},
      parsedTrackingParams: this.state.parsedTrackingParams,
      addSearchParams: this.addSearchParams,
      replaceSearchParams: this.replaceSearchParams,
      removeSearchParams: this.removeSearchParams,
      handleRedirect: this.handleRedirect,
      withinIframe: window.location !== window.parent.location,
      lastMainMobileRoute: this.state.lastMainMobileRoute,
      setLastMainMobileRoute: this.setLastMainMobileRoute,
      pushAndMergeSearch: this.pushAndMergeSearch,
      replaceAndMergeSearch: this.replaceAndMergeSearch,
    };

    return (
      <Router history={history} serverData={this.props.serverData}>
        <Context.Provider value={context}>
          {this.props.children}
        </Context.Provider>
      </Router>
    );
  }

  navigateAndMergeSearch = (method, locationOrPathname) => {
    const { parsedSearchParams } = this.state;

    if (typeof locationOrPathname === 'string') {
      return method({
        pathname: locationOrPathname,
        search: qs.stringify(parsedSearchParams),
      });
    }

    return method({
      ...locationOrPathname,
      search: qs.stringify({
        ...locationOrPathname.search,
        ...parsedSearchParams,
      }),
    });
  };

  pushAndMergeSearch = (locationOrPathname) => {
    return this.navigateAndMergeSearch(history.push, locationOrPathname);
  };

  replaceAndMergeSearch = (locationOrPathname) => {
    return this.navigateAndMergeSearch(history.push, locationOrPathname);
  };

  setLastMainMobileRoute = (lastMainMobileRoute) => {
    this.setState({ lastMainMobileRoute });
  };

  /*
   * Triggered every time history location changes (Route change)
   */
  handleRouteChange = (location, action) => {
    if (config.DEBUG_NAVIGATION) {
      console.info('Route changed: ', action, location);
    }

    const parsedSearch = this.getParsedSearch(location.search);

    // Keep the parsed params in sync as the route changes
    this.setState({
      parsedSearchParams: parsedSearch,
      parsedTrackingParams: this.getParsedTrackingParams(location.search),
    });

    const hasPersistentParam =
      !!location.state &&
      (!!location.state.redirectBackTo || !!location.state.clientId); // Google analytics

    // Get redirectBackTo state param and add it to the URL
    if (hasPersistentParam) {
      // Save redirectBackTo state until handleRedirect is called
      this.setState(
        {
          redirectBackTo: location.state.redirectBackTo,
          clientId: location.state.clientId,
        },
        () => {
          this.keepPersistentParamOnUrl(location);
        },
      );
    }

    // Route changed and would lose the redirect param so we make it stick
    if (
      (!!this.state.redirectBackTo && !parsedSearch.redirect) ||
      (!!this.state.clientId && !parsedSearch.clientId)
    ) {
      this.keepPersistentParamOnUrl(location);
    }
  };

  /*
   * Keep the redirect param on the URL until handleRedirect is called
   */
  keepPersistentParamOnUrl = (location) => {
    const parsedSearch = this.getParsedSearch(location.search);

    let search = {
      ...parsedSearch,
      redirect: this.state.redirectBackTo,
      clientId: this.state.clientId,
    };

    history.replace({
      pathname: location.pathname,
      search: qs.stringify(search),
      hash: window.location.hash,
    });
  };

  /*
   * Return an object with parsed search params
   */
  getParsedSearch = (currentSearch) => {
    if (isBrowser) {
      return qs.parse(currentSearch ?? window.location.search) || {};
    }

    return this.props.serverData.searchParams;
  };

  /*
   * Return an object with parsed search params
   */
  getParsedTrackingParams = (currentSearch) => {
    const parsedParams = this.getParsedSearch(currentSearch);

    return Object.keys(parsedParams).reduce(
      (acc, key) => ({
        ...acc,
        ...(TRACKING_PARAMS.includes(key) ? { [key]: parsedParams[key] } : {}),
      }),
      {},
    );
  };

  /*
   * Gets a key => value object to add as parameters
   * to the url search replacing all previous ones.
   *
   * It respects qs lib object format.
   *
   * It will remove any params that are not in VALID_PARAMS.
   */
  replaceSearchParams = (newSearchParams = {}, state) => {
    const { filteredSearch, filteredOut, filtered } =
      this.filterValidParams(newSearchParams);

    // Only keep valid params on the URL, strip all the rest
    if (filtered) {
      console.info(
        'Some search params were not added cause the were invalid: ',
        filteredOut,
      );
    }

    const search = qs.stringify(filteredSearch);

    history.replace(
      {
        search,
        hash: window.location.hash,
      },
      state,
    );
  };

  /*
   * Gets a key => value object to add as parameters
   * to the url search and keeping all the previous ones.
   *
   * It respects qs lib object format.
   *
   * It will remove any params that are not in VALID_PARAMS.
   */
  addSearchParams = (newSearchParams = {}, state) => {
    const { filteredSearch, filteredOut, filtered } =
      this.filterValidParams(newSearchParams);

    // Only keep valid params on the URL, strip all the rest
    if (filtered) {
      console.info(
        'Some search params were not added cause the were invalid: ',
        filteredOut,
      );
    }

    const currentSearch = this.getParsedSearch();

    const search = qs.stringify({
      ...currentSearch,
      ...filteredSearch,
    });

    history.replace(
      {
        search,
        hash: window.location.hash,
      },
      state,
    );
  };

  /*
   * Gets an Array of keys to remove and strip them from the URL
   */
  removeSearchParams = (...keysToRemove) => {
    const currentSearch = this.getParsedSearch();

    const search = Object.keys(currentSearch).reduce((newSearch, key) => {
      if (keysToRemove.includes(key)) {
        return newSearch;
      }

      return {
        ...newSearch,
        [key]: currentSearch[key],
      };
    }, {});

    history.replace({
      search: qs.stringify(search),
      hash: window.location.hash,
    });

    return new Promise((resolve) => {
      // Force trigger a re render since parsedParams are not a state
      // But we still want it to be updated where it's due
      this.forceUpdate(resolve);
    });
  };

  /*
   * Gets an Object of a parsed url Search and filter out any
   * key that is not in VALID_PARAMS.
   *
   * returns an object with the filtered state, the new filtered search
   * and the params that were filtered out.
   */
  filterValidParams = (parsedSearch) => {
    let filtered = false;
    let filteredOut = {};

    const filteredSearch = Object.keys(parsedSearch).reduce(
      (newSearch, key) => {
        // Remove invalid params
        if (!VALID_PARAMS.includes(key)) {
          // When it gets here it means filtering happened
          filtered = true;
          // Populate filtered out
          filteredOut = { [key]: parsedSearch[key] };
          // Return the current state;
          return newSearch;
        }

        // Add a valid param. Filter didn't happen.
        return {
          ...newSearch,
          [key]: parsedSearch[key],
        };
      },
      {},
    );

    return {
      filtered,
      filteredSearch,
      filteredOut,
    };
  };

  /*
   * Attempt to navigate forth to the url/path provided by the redirect param
   */
  handleRedirect = () => {
    return new Promise((resolve) => {
      // Removes redirectBackTo and then tries to redirect
      this.setState({ redirectBackTo: null }, () => {
        const currentSearch = this.getParsedSearch();

        if (currentSearch.redirect) {
          const uri = decodeURI(currentSearch.redirect);
          this.removeSearchParams('redirect');

          try {
            // We're assuming full URL's will be provided with protocol
            // Attempt to parse it to a URL for external links
            const url = new URL(uri);
            window.location.href = url;
          } catch (_) {
            delete currentSearch.redirect;
            // When it fails to parse a full URL we're dealing with a pathname
            history.replace({
              pathname: uri,
              search: qs.stringify(currentSearch),
              hash: window.location.hash,
            });
          }

          return resolve(true);
        }

        return resolve(false);
      });
    });
  };
}

export const TRACKING_PARAMS = [
  'adgroupid',
  'adposition',
  'campaignid',
  'gclid',
  'keyword',
  'network',
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'clientId',
];

export const APP_FILTER_PARAMS = [
  'filterModalOpen',
  'instantBooking',
  'isFavorite',
  'isOffice',
  'bookingType',
  'capacity',
  'amenities',
  'mood',
  'start',
  'end',
  'city',
  'date',
  'lat',
  'lng',
  'query',
];

export const DESKPASS_PARAMS = [
  'coupon',
  'discount',
  'trialUser',
  'redirect',
  'signup',
  'email',
  'zip',
  'country',
  'from',
  'to',
  'loginSource',
];

// White list of valid query params
export const VALID_PARAMS = [
  ...TRACKING_PARAMS,
  ...APP_FILTER_PARAMS,
  ...DESKPASS_PARAMS,
];

export default Context;
export const RouterProvider = Provider;
export const useRouterContext = () => useContext(Context);
export const withRouterContext = consumerToHOC(
  Context.Consumer,
  'routerContext',
);
